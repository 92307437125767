import { registerLinkResolver } from 'gatsby-source-prismic-graphql'
import { linkResolver } from './src/utilities/linkResolver'
// import { configureIubendaGTMCallbacks } from './src/utilities/iub'
import GlobalStateWrapper from './src/components/global_state_provider'
import 'src/css/site.css'
import 'src/css/braze.css'
import 'src/fonts/fonts.css'

export const wrapRootElement = GlobalStateWrapper;
registerLinkResolver(linkResolver);
// configureIubendaGTMCallbacks();

export const onRouteUpdate = ({ prevLocation }) => {
  // Initial page load is already recorded, so we only want to record page changes
  if (prevLocation) {
    window.analytics && window.analytics.page();
  }
};