const DEFAULT_LOCALE = 'en-nz'
const SANDBOX_LOCALE = 'en-xx'

// Expected format colon & comm separated languageCode:urlPath:geolocationCode:languageCountry:countryId
// e.g. en-au:au:au:Australia:2,en-nz:nz:nz:New Zealand:1,en-gb:uk:gb:United Kingdom:3
let envLangs = {}
try {
  if (process.env.GATSBY_LANGUAGE_CONFIG) {
    const lines = process.env.GATSBY_LANGUAGE_CONFIG.split(',').map(line => line.split(':'))
    const buildLangObject = (x,ctype) => (obj, config) => ({ ...obj, [config[0]]: ctype(config[x]) })
    envLangs.langAliases = lines.reduce(buildLangObject(1, v => v), {})
    // envLangs.geolocationAliases = lines.reduce(buildLangObject(2, v => v), {})
    envLangs.countryNames = lines.reduce(buildLangObject(3, v => v), {})
    envLangs.countryIds = lines.reduce(buildLangObject(4, parseInt), {})
  }
} catch (error) {
  envLangs = {}
  console.log({message: "Failed to read GATSBY_LANGUAGE_CONFIG", error})
}

const langAliases = envLangs.langAliases || {
  'en-nz': 'nz',
  'en-xx': 'xx',
  //'en-gb': 'uk',
  //'en-au': 'au',
}

// const geolocationAliases = envLangs.geolocationAliases || {
//   'en-nz': 'nz',
//   'en-gb': 'gb',
//   'en-au': 'au',
//   'en-xx': 'xx',
// }

const countryNames = envLangs.countryNames || {
  'en-nz': 'New Zealand',
  'en-xx': 'Sandbox',
  //'en-au': 'Australia',
  //'en-gb': 'United Kingdom',
}

const countryIds = envLangs.countryIds || {
  'en-nz': 1,
  //'en-au': 2,
  //'en-gb': 3,
  'en-xx': 2,
}

const alias = (locale) => langAliases[locale] || locale

const localeName = lang => countryNames[lang] || lang

const switchLocale = locale =>
  window.localStorage.laybuyLocale = locale

const determineLocale = async (checkOnly) => {
  let laybuyLocale = DEFAULT_LOCALE;

  // Switch to Sandbox locale for non-prod or in local development env
  if(process.env.CONTEXT === 'branch-deploy' || process.env.CONTEXT === 'deploy-preview' || process.env.NODE_ENV === 'development'){
    laybuyLocale = SANDBOX_LOCALE;
  }
  
  // Laybuy by Klarna - Disable other locales and only keep NZ
  // let laybuyLocale = window.localStorage.laybuyLocale || DEFAULT_LOCALE
  // if(!window.localStorage.laybuyLocale || !(window.localStorage.laybuyLocale in langAliases) || checkOnly){
  //   try{
  //     const { country_code: countryCode } = await fetch(process.env.GATSBY_GEOLOCATION_SERVICE_URL).then(res => res.json())
  //     const matchingLocales = Object.entries(geolocationAliases).find(
  //       ([locale, code]) => code.toLowerCase() === countryCode.toLowerCase()
  //     ) || []
  //     laybuyLocale = (matchingLocales[0] || '').toLowerCase()
  //   }
  //   catch(err){
  //     console.error(err, `Error determining locale, returning default: ${DEFAULT_LOCALE}`)
  //   }
  // }

  //Only overwrite our default locale if
  //this is not a checkOnly request
  if(!checkOnly){
    laybuyLocale = laybuyLocale || DEFAULT_LOCALE
    switchLocale(laybuyLocale)
  }

  return laybuyLocale
}

module.exports = {
  langAliases, alias, determineLocale, countryNames, localeName, switchLocale, countryIds
}